import React from "react"
import { Box } from "@chakra-ui/core"

import PageContainer from "../../components/PageContainer"
import UserOnlyAccessWrapper from "../../components/Account/UserOnlyAccessWrapper"
import Header from "../../components/Account/Header"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"

const PedalPointsPage = () => {
  const dispatch = useDispatch<BaseDispatch>()
  React.useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
    dispatch.user.refreshUserData()
  }, [])

  return (
    <PageContainer>
      {/* <MaxWidthGrid>
        <UserOnlyAccessWrapper>
          <Header active={"pedal-points"}>
            <Box>
              <div data-lion-account></div>
            </Box>
          </Header>
        </UserOnlyAccessWrapper>
      </MaxWidthGrid> */}
    </PageContainer>
  )
}
export default PedalPointsPage
